import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { msalInstance } from "./lib/authConfig";
import { getToken } from "./lib/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { getUserData } from "./utils/utils";
import axios from "axios";
import { RouterProvider } from "react-router-dom";
import Router from "./routes"
// import reportWebVitals from './reportWebVitals';

const data = getUserData("user_data");
if (data) {
  axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
}

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response?.status && err.response.status === 401) {
      delete axios.defaults.headers.common.Authorization;
      getToken();
    }
    return Promise.reject(err);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <RouterProvider router={Router}></RouterProvider>
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
