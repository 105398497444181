import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectList: [],
  rowData: {},
  projectData: {},
  selectedProject: {},
  selectedInsight: "",
  selectedInsightDetails: [],
  insightsDropdown: [],
  selectedValue: {},
  loading: false,
};

export const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
    setProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    setRowData: (state, action) => {
      state.rowData = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    updateProjectData: (state, action) => {
      state.projectData = action.payload;
    },
    setSelectedInsightDetails: (state, action) => {
      state.selectedInsightDetails = action.payload;
    },
    setSelectedInsight: (state, action) => {
      state.selectedInsight = action.payload;
    },
    setInsightsDropdown: (state, action) => {
      state.insightsDropdown = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setProjectList,
  updateProjectData,
  setSelectedInsightDetails,
  selectedInsightDetails,
  setSelectedInsight,
  setInsightsDropdown,
  setSelectedProject,
  setRowData,
  setLoading,
  setSelectedValue,
} = projectDetailsSlice.actions;

export default projectDetailsSlice.reducer;
