import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appList: [
    {
      name: "",
      value: "",
      link: "",
      route: "",
      id: 0,
    },
    {
      name: "Codeset",
      value: "codeset",
      route: "/codeset",
      id: 1,
    },
    {
      name: "Cohort Builder",
      value: "cohort-builder",
      route: "/cohort-builder",
      id: 2,
    },
    {
      name: "Attributes Definer",
      value: "attributes-definer",
      route: "/attributes-definer",
      id: 3,
    },
  ],
  selectedAppName: "",
  refreshRequiredForCodeset: false,
  refreshRequiredForCohortBuilder: false,
  refreshRequiredForAttributesDefiner: false,
};

export const HeaderSlice = createSlice({
  name: "HeaderSlice",
  initialState,
  reducers: {
    setSelectedAppName: (state, action) => {
      state.selectedAppName = action.payload;
    },
    setRefreshRequiredForCodeset: (state, action) => {
      state.refreshRequiredForCodeset = action.payload;
    },
    setRefreshRequiredForCohortBuilder: (state, action) => {
      state.refreshRequiredForCohortBuilder = action.payload;
    },
    setRefreshRequiredForAttributesDefiner: (state, action) => {
      state.refreshRequiredForAttributesDefiner = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedAppName,
  setRefreshRequiredForCodeset,
  setRefreshRequiredForCohortBuilder,
  setRefreshRequiredForAttributesDefiner,
} = HeaderSlice.actions;

export default HeaderSlice.reducer;
