// import HomePage from './features/home/HomePage';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import { Header } from "./features/common/Header";
// import Router from "./routes/index";
// import MainLayout from "./MainLayout";

import "./App.css";

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      {/* <Header className="App-header" />
      <div className="content-padding-top">
      <Router />
      </div> */}
    </div>
  );
}

export default App;
