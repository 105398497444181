import { createBrowserRouter } from "react-router-dom";
import React, { lazy } from "react";

const MainLayout = lazy(() => import("../MainLayout"));
const NotFound = lazy(() => import("../features/common/NotFound"));
const HomePage = lazy(() => import("../features/home/HomePage"));
const Codeset = lazy(() => import("../features/integratedApps/codeset"));
const Cohort = lazy(() => import("../features/integratedApps/cohort"));
const AttributesBuilder = lazy(() => import("../features/integratedApps/attributesDefiner"));
const ProjectDetails = lazy(() => import("../features/projectDetails/ProjectDetails"));
const Insights = lazy(() => import("../features/insightsPage/InsightsPage"));

const router = createBrowserRouter([
  {
    path: "",
    element: <MainLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/codeset",
        element: <Codeset />,
      },
      {
        path: "/cohort-builder",
        element: <Cohort />,
      },
      {
        path: "/attributes-definer",
        element: <AttributesBuilder />,
      },
      {
        path: "/:id",
        element: <ProjectDetails />,
      },
      {
        path: "/:id/insights",
        element: <Insights />,
      },
    ],
  },
  // {
  //   path: "*",
  //   element: <MainLayout />,
  //   errorElement: <NotFound />,
  // },
]);

export default router;

// import React, { lazy, Suspense } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { connect } from "react-redux";
// import Spinner from "./Spinner";

// const NotFound = lazy(() => import("../features/common/NotFound"));
// const HomePage = lazy(() => import("../features/home/HomePage"));
// const Codeset = lazy(() => import("../features/integratedApps/codeset"));
// const Cohort = lazy(() => import("../features/integratedApps/cohort"));
// const AttributesBuilder = lazy(() =>
//   import("../features/integratedApps/attributesDefiner")
// );

// const ProjectDetails = lazy(() =>
//   import("../features/projectDetails/ProjectDetails")
// );
// const Insights = lazy(() => import("../features/insightsPage/InsightsPage"));

// const Routers = (props) => {
//   return (
//     <BrowserRouter>
//       <Suspense fallback={<Spinner />}>
//         <Routes>
//           <Route exact path="/" element={<HomePage />} />
//           <Route exact path="/codeset" element={<Codeset />} />
//           <Route exact path="/cohort-builder" element={<Cohort />} />
//           <Route exact path="/attributes-definer" element={<AttributesBuilder />} />
//           <Route path="/:id" element={<ProjectDetails />}></Route>
//           <Route path="/:id/insights" element={<Insights />} />
//           <Route exact path="*" element={<NotFound />} />
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   );
// };

// export default connect()(Routers);